:export {
  mediumScreen: 992px;
  smallScreen: 540px; }

.side-nav {
  max-width: calc(100vw - 5rem);
  width: 30rem; }
  .side-nav__menu-item {
    border-bottom: 1px solid #f6f6f6;
    display: grid;
    height: 3.7rem; }
    .side-nav__menu-item-link,
    .side-nav__menu-item-back span {
      align-items: center;
      color: #323232;
      display: flex;
      font-weight: 600;
      padding: 1rem;
      text-decoration: none;
      text-transform: uppercase;
      transition: 0.3s; }
      .side-nav__menu-item-link svg *,
      .side-nav__menu-item-back span svg * {
        fill: currentColor; }
    .side-nav__menu-item-logo {
      align-self: center;
      padding: 0.75rem 1rem; }
      .side-nav__menu-item-logo svg {
        width: 5rem; }
    .side-nav__menu-item-link, .side-nav__menu-item-more, .side-nav__menu-item-back, .side-nav__menu-item-close {
      transition: 0.3s; }
      .side-nav__menu-item-link:hover, .side-nav__menu-item-more:hover, .side-nav__menu-item-back:hover, .side-nav__menu-item-close:hover {
        color: #51e9d2; }
      .side-nav__menu-item-link svg *, .side-nav__menu-item-more svg *, .side-nav__menu-item-back svg *, .side-nav__menu-item-close svg * {
        fill: currentColor; }
    .side-nav__menu-item-close:hover, .side-nav__menu-item-more:hover, .side-nav__menu-item-back:hover {
      cursor: pointer; }
    .side-nav__menu-item-more {
      align-self: center;
      padding: 1.25rem 1.75rem; }
    .side-nav__menu-item-back svg {
      margin-right: 1rem; }
    .side-nav__menu-item-close {
      align-items: center;
      display: flex;
      height: 3.7rem;
      justify-content: center; }
      .side-nav__menu-item-close span {
        background-color: currentColor;
        border-radius: 1px;
        height: 3px;
        width: 24px; }
      .side-nav__menu-item-close:hover {
        color: #51e9d2; }
    .side-nav__menu-item--has-subnavigation, .side-nav__menu-item--parent {
      grid-template-columns: 1fr 3.7rem; }
    .side-nav__menu-item--parent {
      color: #c4c4c4; }
      .side-nav__menu-item--parent a {
        color: inherit; }
