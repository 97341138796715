:export {
  mediumScreen: 992px;
  smallScreen: 540px; }

button.button {
  background-color: #51e9d2;
  transform: skew(45deg);
  padding: 0.9rem 3.7rem;
  border: none;
  box-shadow: -5px 5px 14px 0px rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  outline: none;
  font-family: "Inter", sans-serif;
  cursor: pointer; }
  @media (max-width: 540px) {
    button.button {
      padding: 0.9rem 1rem;
      width: 88%;
      max-width: 88%; } }
  button.button > span {
    display: inline-block;
    color: #fff;
    font-size: 1.125rem;
    text-transform: uppercase;
    font-weight: 600;
    line-height: 1.25rem;
    transform: skew(-45deg); }
  button.button:hover {
    background-color: #55c4b3; }
  button.button:active {
    box-shadow: -3px 3px 14px 0px rgba(129, 67, 67, 0.2); }
  button.button.secondary {
    background-color: #fff;
    box-shadow: inset 0px 0px 0px 3px #21125e;
    border-left: 1px solid #21125e;
    border-right: 1px solid #21125e; }
    button.button.secondary * {
      color: #21125e; }
    button.button.secondary:hover {
      background-color: #21125e; }
      button.button.secondary:hover * {
        color: #fff; }
    button.button.secondary:active {
      background-color: #190c4e; }
  button.button:disabled {
    background-color: #c4c4c4; }
    button.button:disabled, button.button:disabled:hover {
      cursor: default; }
