:export {
  mediumScreen: 992px;
  smallScreen: 540px; }

.social-icon {
  padding: 1rem 0.5rem; }
  .social-icon svg * {
    transition: 0.3s; }
  .social-icon:hover svg * {
    fill: #51e9d2; }
