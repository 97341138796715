:export {
  mediumScreen: 992px;
  smallScreen: 540px; }

.react-select-wrapper {
  position: relative; }
  .react-select-wrapper .input__label {
    background-color: #fff;
    font-size: 0.75rem;
    left: 17px;
    top: 0; }
  .react-select-wrapper--grey .input__label,
  .react-select-wrapper--grey .react-select__control {
    background-color: #f1f5f5; }
  .react-select-wrapper .react-select__control {
    border-radius: 0;
    border-color: #21125e; }
    .react-select-wrapper .react-select__control:hover {
      border-color: #21125e; }
    .react-select-wrapper .react-select__control--is-focused {
      box-shadow: 0 0 0 1px #21125e; }
  .react-select-wrapper .react-select__placeholder {
    font-weight: 600;
    color: #7d7d7d; }
  .react-select-wrapper .react-select__value-container {
    padding: 0.5rem 1rem; }
  .react-select-wrapper .react-select__indicator-separator {
    display: none; }
  .react-select-wrapper .react-select__dropdown-indicator {
    background: url("../../../images/arrow.svg");
    background-repeat: no-repeat;
    background-position: center;
    margin-right: 1rem; }
    .react-select-wrapper .react-select__dropdown-indicator svg {
      display: none; }
  .react-select-wrapper .react-select__menu {
    margin-top: 0;
    border-radius: 0;
    box-shadow: 0 0 0 1px #21125e;
    border: 1px solid #21125e;
    z-index: 2; }
  .react-select-wrapper .react-select__menu-list {
    box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.15);
    padding-bottom: 0;
    padding-top: 0; }
    .react-select-wrapper .react-select__menu-list div {
      padding: 1rem 1.5rem;
      width: auto;
      position: relative; }
      .react-select-wrapper .react-select__menu-list div:not(:last-child)::after {
        background-color: #c4c4c4;
        bottom: 0;
        content: "";
        height: 1px;
        left: 1rem;
        position: absolute;
        width: calc(100% - 2rem); }
  .react-select-wrapper .react-select__option:hover {
    cursor: pointer;
    background-color: rgba(33, 18, 94, 0.1); }
  .react-select-wrapper .react-select__option--is-focused {
    background-color: #fff; }
  .react-select-wrapper .react-select__option--is-selected,
  .react-select-wrapper .react-select__option--is-focused .react-select-wrapper .react-select__option--is-selected, .react-select-wrapper .react-select__option:active {
    background-color: #f1f5f5;
    color: #323232; }
