:export {
  mediumScreen: 992px;
  smallScreen: 540px; }

.footer__favicons {
  border-top: 1px solid #f6f6f6;
  display: flex;
  justify-content: center; }

.footer__menu {
  background-color: #f6f6f6;
  padding: 3rem 0 2rem; }
  .footer__menu .container {
    display: flex;
    flex-wrap: wrap; }
    .footer__menu .container div:not(:last-of-type) {
      padding-right: 3rem; }
  .footer__menu-section-header {
    font-weight: 600;
    margin-bottom: 1rem; }
  .footer__menu-section-content p {
    font-weight: 600;
    margin-bottom: 1rem;
    font-size: 0.875rem;
    color: #c4c4c4; }
    .footer__menu-section-content p a {
      text-decoration: none;
      color: #c4c4c4; }

.footer-nav {
  background-color: #f6f6f6;
  padding: 3rem 0 2rem; }
  .footer-nav .container {
    display: flex;
    flex-wrap: wrap; }
    .footer-nav .container div:not(:last-of-type) {
      padding-right: 3rem; }
  .footer-nav__section-header {
    font-weight: 600;
    margin-bottom: 1rem;
    text-transform: uppercase; }
  .footer-nav__section-content p {
    margin-bottom: 1rem;
    font-size: 0.875rem;
    color: #c4c4c4; }
    .footer-nav__section-content p a {
      text-decoration: none;
      color: #c4c4c4; }
