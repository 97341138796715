:export {
  mediumScreen: 992px;
  smallScreen: 540px; }

.product-list-item {
  text-align: center; }
  .product-list-item__title {
    text-transform: uppercase;
    font-weight: 600;
    margin-top: 1rem;
    text-align: left; }
  .product-list-item__undiscounted_price {
    text-decoration: line-through;
    color: #c4c4c4;
    font-size: 0.875rem;
    font-weight: regular; }
  .product-list-item__category {
    color: #c4c4c4;
    text-align: left; }
  .product-list-item__price {
    font-weight: 600;
    margin: 1rem 0 0;
    text-align: left; }
  .product-list-item__image {
    background-color: #f1f5f5;
    display: flex;
    height: 350px;
    justify-content: center; }
    .product-list-item__image img {
      object-fit: contain;
      max-width: 100%; }
    @media (max-width: 540px) {
      .product-list-item__image {
        height: 210px; } }
